.detail-view {
  border-radius: 0.4em;
}

.principal {
  /* background-color: var(--selected-color); */


  /* border: none !important; */
}
.suggested {
  margin-bottom: 0;

}

.detail-related-thoughts > .search-feed {
  margin: 0;
}
.detail-related-thoughts {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.detail-related-thoughts-label {
  margin-bottom: 0.5em;
  font-size: 1em;
  text-align: center;
  cursor: pointer;
}
