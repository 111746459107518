.reply {
  cursor: text !important;
  padding: 0.5em 0 .5em 1em;
  height: fit-content;
  border-bottom: 2px solid transparent;
}
.reply:focus-within{
/* border-color: black; */
}
.reply-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
}
.submit-reply {
  /* font-size: 2em; */
}
