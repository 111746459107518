body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.simple-button {
  cursor: pointer;
  /* background-color: rgb(236, 236, 236); */
  padding: 0.4em;
  margin: 0.1em;
  height: fit-content;
  cursor: pointer;
  border-radius: 0.3em;
  /* font-style: italic; */
  border: 2px solid black;
}
.simple-button:active {
  background-color: black;
  color: white;
}
