.feed-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.feed-cont-cont-cont {
  margin: 3vh 2vh;
  display: flex;
  flex-direction: column;


}
.feed-item-container-container {
  display: flex;
  flex-direction: column;
  border: 1px solid transparent;


}
.feed-item-container.isPinned {
  background-color: rgb(239, 240, 245);
}


.feed-item-container {
  cursor: pointer;
  min-height: 1em;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  /* background-color: rgba(41, 43, 52, 0.03); */
  outline: solid 2px rgb(0, 0, 0);
  /* border-radius: .3em; */
  /* border-left: 1px black solid; */
  
  padding: 0.2em 0.2em 0.4em 0.4em;
 
  /* border: 1px rgb(236, 235, 243) solid; */
 

  /* background-color: rgba(26, 26, 42, 0.042); */
}
.feed-item-container:active {
  /* background-color: var(--selected-color); */
}
/* .feed-item-container-container:not(.top-level){
  background-color: rgba(174, 174, 174, 0.072);
  } */
.feed-item-container.principal {
  margin-bottom: 0;
}


/* toggle */
.feed-item-container.expanded {
  /* border-color: transparent; */

}
.feed-item-container-container.expanded {

  /* border: 1px solid black; */

}
.section-label {
  color: rgb(165, 165, 165);
  margin: .4em;
  margin-bottom: 0;
}


.feed-item-header {
  display: flex;
  flex-direction: row;
  margin: 0.3em 0 0;
  color: gray;
  font-size: 80%;
  align-self: "flex-end";
 
}
.feed-item.bubbley {
  /* box-shadow: var(--super-bubble); */
}
/* .feed-item:active {
  border-radius: 0.4em;
  background-color: rgb(239, 237, 255);
} */
.feed-item-buttons {
  /* align-items: center; */
  min-width: 3em;
 
  
}

.feed-item-button.pin {
  width: fit-content;
  border-radius: 0.5em;
  transition: background-color 0.1s, padding 0.2s, margin 0.2s, border-radius 0.2s;
}
.feed-item-button.pin:active {
  color: var(--active-purple);
  outline: none;
}
.feed-item-button.invisible {
  visibility: hidden;
}
.author-tag.emailable {
  color: blue;
  font-weight: bold;
}
.link-button {
  /* border: 1px black solid; */
}
.link-button:active {
  background-color: black;
  font-weight: bold;
  color: #ffffff;
}
