.pin-button {
  min-width: 1.5em;
  align-self: flex-start;
  width: fit-content;
  font-size: 2em;
  padding: .3em .2em;
  margin: .3em;
  border-radius: 0.2em;
  text-align: center;
  border-radius: 100%;
  cursor: pointer;
  /* border: 1px solid black; */
  /* background-color: rgb(246, 246, 246); */
  /* font-weight: bold; */
}
.pin-button.unlink {
  /* background: rgb(154, 174, 255); */
  /* border: solid 1px black; */


}

.pin-button:active {
  background: white;
  outline: 2px solid rgb(0, 0, 0);

}
