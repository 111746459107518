:root {
  --bubble: 0 0 0.4em 1px rgb(240, 240, 240);
  --super-bubble: 0 0 01em rgb(208, 208, 208);
  --active-pink: hotpink;
  --active-purple: #400bff;
  /* --selected-color: rgb(232, 237, 255); */
}

.App {
  word-break: break-word;

  /* breaking */
  height: 100vh;
  width: 100%;
  font-size: 17px;
  font-family: "Inter", "Noto Sans", "Roboto", "Nunito", "Sarabun", "Cambay", "Poppins",
    "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva,
    "Trebuchet MS", Verdana, sans-serif;

  /* background-color:white; */
  /* background-color: rgb(55, 55, 55); */
}
.wordMatching {
  color: gray;
  /* color: rgb(96, 96, 96); */
}

/* was for side by side view */
/* @media screen and (max-width: 32em) {
  .create-panel.searchOpenToo {
    display: none !important;
  }
} */

.row {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}
.column {
  display: flex;
  flex-direction: column;
}
.panel {
  overflow-y: visible;
  transition: width 1s;
}
.secondary-panel {
  padding: 0;
  height: 100%;
  align-items: center;
  /* border-left: 1px solid rgb(233, 233, 233); */
}

.post-section {
  margin: 3vh;
  padding: 0.6em;
  border-radius: 0.3em;
  flex: 0;
  flex-basis: auto;
  min-height: 5em;
  height: fit-content;
  transition: width 0.3s;
  overflow: visible;
}

.big-panel {
  flex: 1;
  align-items: stretch;
  align-self: center;
  overflow: auto;
  height: 100vh;
  width: 100%;
  max-width: 37em;
}
.create-panel {
  display: flex;
  align-items: stretch;
  justify-content: stretch;
  height: fit-content;
}
