.landing-page {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  font-size: 1.1em;
  text-align: left;
}
.landing-page-text {
  max-width: 28em;
  padding: 2em;
  display: flex;
  flex-direction: column;
}
