

.linked-thoughts.above> * {
  margin: 0;
}

.linked-thoughts.above >*> .feed-item-container-container {

  opacity: .5;
  font-size: 0.8em;
  border-bottom: none;
}

.linked-thoughts.above > * > * > .feed-item-container {
  border-color: gainsboro;
  border-bottom: none;
  outline-color: gainsboro;
}
.linked-thoughts > * {
  margin: .5em .5em;
  font-size: .9em;
}

