.post-button {
  cursor: pointer;
  height: fit-content;
  align-self: flex-end;
  font-style: normal;
  font-size: 1em;
}
.post-button:active {
  background-color: var(--active-pink);
  color: var(--active-purple);
}
